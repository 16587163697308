import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import styles from './SearchSorter.module.css';
import Form from 'react-bootstrap/Form';
import { SortContext } from 'contexts/SiteSettingsProvider';

const SearchSorter = ({ updateSorting }) => {
  const { sort, order } = useContext(SortContext);

  const getValidCombinedValue = (sort, order) => {
    const possibleValues = [
      "propertymaxrate:asc", "propertymaxrate:desc",
      "sleeps:asc", "sleeps:desc",
      "bedrooms:asc", "bedrooms:desc",
      "nodeName:asc", "propertyReference:asc",
      "ourpick:asc"
    ];

    // Check if sort and order match the format
    let combinedValue = `${sort}:${order}`;
    if (possibleValues.includes(combinedValue)) {
      return combinedValue;
    }

    // If not, try reversing them
    combinedValue = `${order}:${sort}`;
    if (possibleValues.includes(combinedValue)) {
      return combinedValue;
    }

    // Return empty if neither match
    return "";
  };

  useEffect(() => {
 
    const combinedValue = getValidCombinedValue(sort, order);

    const selectElement = document.getElementById('sortSelect');
    if (selectElement && combinedValue) {
      selectElement.value = combinedValue;
    }
  }, [sort, order]);

  const handleSelectChange = (event) => {
    const value = event.target.value;
    const [newSort, newOrder] = value.split(':');
    updateSorting(newSort, newOrder);
  };

  return (
    <div className={styles.SearchSorter}>
      <Form.Select id="sortSelect" onChange={handleSelectChange}>
        <option value="">Please Select</option>
        <option value="propertymaxrate:asc">Price low to high</option>
        <option value="propertymaxrate:desc">Price high to low</option>
        <option value="sleeps:asc">Sleeps: ascending</option>
        <option value="sleeps:desc">Sleeps: descending</option>
        <option value="bedrooms:asc">Bedrooms: ascending</option>
        <option value="bedrooms:desc">Bedrooms: descending</option>
        <option value="nodeName:asc">Property name</option>
        <option value="propertyReference:asc">Property reference</option>
        <option value="ourpick:asc">Our Pick</option>
      </Form.Select>
    </div>
  );
};

SearchSorter.propTypes = {
  updateSorting: PropTypes.func.isRequired,
};

export default SearchSorter;
